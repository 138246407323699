jQuery(function($) {

  /**
  * Signature :)
  */
  console.log('%c Made with ♡ by Choosit! ', 'background: #009ee0; color: #fff');

  /**
  * Polyfills
  */
  // ### Closest support
  // eg: el.closest(".lol")
  if (window.Element && !Element.prototype.closest) {
    Element.prototype.closest =
    function(s) {
      var matches = (this.document || this.ownerDocument).querySelectorAll(s),
      i,
      el = this;
      do {
        i = matches.length;
        while (--i >= 0 && matches.item(i) !== el) {};
      } while ((i < 0) && (el = el.parentElement));
      return el;
    };
  }



  /**
  * Foundation 6 Init
  */
  $(document).foundation();

  /**
  * Menus
  * Handle menu cloning on small devices
  * And sticky menu on all devices
  * @author sylvain@choosit.com
  */
  // Vars
  var b            = document.body;
  var menuToClone  = document.querySelector('.header__nav');

  if( menuToClone ){
    // Clone and keep events the primary navigation
    var menuMobile = menuToClone.cloneNode(true);
    menuMobile.classList.add("mobile");
    menuMobile.setAttribute('id','menu-mobile');
    b.appendChild(menuMobile);
    // Create a backdrop
    var menuBackDrop = document.createElement("div");
    menuBackDrop.classList.add('backdrop');
    b.appendChild(menuBackDrop);
  }

  // Bonus Dropdown Menu
  // Cancel Click Event on Dropdown parent
  var dropdowns;

  if ( Foundation.MediaQuery.current === 'small' || Foundation.MediaQuery.current == 'medium' ) {
    dropdowns = document.querySelectorAll('#menu-mobile .expanded > a');
    [].forEach.call(dropdowns, function(el) {
      var self = el;
      el.addEventListener('click', function(e){
        e.preventDefault();
      });
    });
  } else{
    dropdowns = document.querySelectorAll('#header .expanded > a');
    [].forEach.call(dropdowns, function(el) {
      var self = el;
      el.addEventListener('click', function(e){
        e.preventDefault();
      });
    });
  }





  /**
  * Masonry + ImagesLoaded
  * @author sylvain@choosit.com
  */
  // ### Featured Bloc
  var featuredMasonry = document.querySelector('#featured_masonry');
  if( featuredMasonry ){
    // Stamp
    var masonryStamp = featuredMasonry.querySelector('.stamp');
    // Options
    var masonryOptions = {
      masonry: {
        columnWidth: '.grid-sizer',
        gutter: '.gutter-sizer'
      },
      itemSelector: '.masonry__item',
      percentPosition: true,
      stamp: masonryStamp
    }
    // Init
    var featuredIsotope = new Isotope( featuredMasonry, masonryOptions);
    // ImagesLoaded
    var featuredImagesLoaded = imagesLoaded( featuredMasonry );
    featuredImagesLoaded.on( 'progress', function( instance, image ) {
      featuredIsotope.layout();
    });
  }
  // ### Generic Masonry
  var masonryDefault = document.querySelectorAll('.js-masonry');
  var masonryOptions;
  var hasImages;
  var iso = [];

  if( masonryDefault ){

    // Init for each instances
    [].forEach.call(masonryDefault, function(el, k) {
      hasImages = el.querySelectorAll('img');

      masonryOptions = {
        masonry: {
          columnWidth: el.querySelector('.grid-sizer'),
          gutter: el.querySelector('.gutter-sizer')
        },
        itemSelector: '.masonry__item',
        percentPosition: true
      };

      iso[k] = new Isotope( el, masonryOptions);
      var imgLoaded = imagesLoaded( el );

      if( hasImages.length ){
        imgLoaded.on( 'progress', function( instance, image ) {
          image.img.closest('.masonry__item').classList.add('loaded');
          iso[k].layout();
        });
      } else{
        setTimeout( function () {
          iso[k].layout();
        }, 1);
      }


    });

  }



  /**
  * Swiper Carousel
  * @author sylvain@choosit.com
  */
  // ### Agenda
  // Agenda Content
  var agendaSlides = new Swiper('.agenda__body .swiper-container', {
    autoHeight: true
    //speed: 800
    //spaceBetween: 10,
  });
  // Agenda Onglets
  var agendaDates = new Swiper('.agenda__head .swiper-container', {
    speed: 500,
    slidesPerView: 'auto',
    centeredSlides: true,
    touchRatio: 0.2,
    slideToClickedSlide: true,
  });
  // 2-Way Controls (speed is the same on both)
  agendaSlides.params.control = agendaDates;
  agendaDates.params.control = agendaSlides;

  // ### Partenaires
  // Disable Swiper because We have only 1 partner now 😅
  // var partnersSlides = new Swiper('.bloc--partners .swiper-container', {
  //   speed: 600,
  //   autoplay: 5000,
  //   slidesPerView: 2,
  //   loop: true,
  //   breakpoints: {
  //     640: { // when window width is <= 640px
  //       slidesPerView: 2
  //     }
  //   }
  // });



  /**
  * Open / Close Mobile Navigation
  * @author sylvain@choosit.com
  */
  var navOpened = false;
  var navTrigger = document.querySelector("#trigger-menu");
  // Velocity Els to animate
  var $navBackground = $("#menu-mobile");
  var $navItems = $(".mobile .menu__item");
  var $socialItems = $(".mobile .social-pages");
  var $backDrop = $(".backdrop");

  //var $stickyMenu = $(stickyMenu);
  var toggleNav = function(e){
    e.preventDefault();

    if(!navOpened){
      // Nav is closed, we open it
      navOpened = true;
      b.classList.add('nav-opened');
      $navBackground.velocity({
        translateX: ["0%", "100%"]
      }, {
        easing: 'easeOutExpo',
        duration: 400
      });
      $navItems.velocity('transition.slideUpBigIn', {
        easing: "easeOutExpo",
        duration: 500,
        stagger: 40
      });
      $socialItems.velocity('transition.expandIn', {
        delay: 500,
        duration: 300
      });
      $backDrop.velocity('transition.fadeIn', 300);
    } else{
      // Nav is open, we close it
      navOpened = false;
      b.classList.remove('nav-opened');
      $socialItems.velocity('transition.expandOut', {
        duration: 300
      });
      $navItems.velocity('transition.slideDownOut', {
        easing: "easeOutExpo",
        duration: 200,
        stagger: 10,
        display: 'block',
        complete: function(el){
          $navBackground.velocity({ translateX: ["100%", "0%"] }, {
            easing: 'easeOutQuart',
            duration: 400
          });
          $backDrop.velocity('transition.fadeOut', 300);
        }
      });

    }
  };
  if( navTrigger ){
    navTrigger.addEventListener('click', toggleNav);
    menuBackDrop.addEventListener('click', toggleNav);
  }



  /**
  * Open / Close Filters
  * @author sylvain@choosit.com
  */
  var filtersTrigger = document.querySelector(".js-toggle-filters");
  if( filtersTrigger ){

    var filtersOpened = false;
    var filtersTrigger = document.querySelector(".js-toggle-filters");
    var $filtersContainer = $(".view-filters");
    var toggleFilters = function(e){
      e.preventDefault();

      if(!filtersOpened){
        // filters are closed, we open them
        filtersOpened = true;
        b.classList.add('filters-opened');
        $filtersContainer.stop().velocity('transition.slideDownIn', 300);
      } else{
        // filters are open, we close them
        filtersOpened = false;
        b.classList.remove('filters-opened');
        $filtersContainer.stop().velocity('transition.slideUpOut', 300);
      }
    };
    filtersTrigger.addEventListener('click', toggleFilters);

  }




  /**
  * Smooth Scroll
  * Using Jump.js loaded via gulpfile.js in `plugins/jump.js`
  */
  var mapBtn = document.querySelector('.button-map');
  var mapTarget = document.querySelector('.view-display-id-block_gmap');

  if( mapBtn ){
    mapBtn.addEventListener('click', function(e){
      e.preventDefault();

      Jump(mapTarget, {
        offset: -100,
        duration: 1000
      });
    });
  }



  /**
  * Smooth Scroll
  * Using Jump.js loaded via gulpfile.js in `plugins/jump.js`
  */
  var tips = new Tippy('.tippy', {
    animation: 'scale',
    html: '#zone',
    duration: 200,
    //inertia: true,
    animateFill: false,
    arrow: false,
    followCursor: false,
    theme: 'transparent'
  });
  // Debug
  // var popper = tips.getPopperElement(document.querySelector('.tippy'))
  // tips.show(popper);


  /**
  * PhotoSwipe JS
  * We need zoom feature for map
  * @author sylvain@choosit.com
  */
  var initPhotoSwipeFromDOM = function(gallerySelector) {

    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function(el) {
      var thumbElements = el.childNodes,
      numNodes = thumbElements.length,
      items = [],
      figureEl,
      linkEl,
      size,
      item;

      for(var i = 0; i < numNodes; i++) {

        figureEl = thumbElements[i]; // <figure> element

        // include only element nodes
        if(figureEl.nodeType !== 1) {
          continue;
        }

        linkEl = figureEl.children[0]; // <a> element

        size = linkEl.getAttribute('data-size').split('x');

        // create slide object
        item = {
          src: linkEl.getAttribute('href'),
          w: parseInt(size[0], 10),
          h: parseInt(size[1], 10)
        };



        if(figureEl.children.length > 1) {
          // <figcaption> content
          item.title = figureEl.children[1].innerHTML;
        }

        if(linkEl.children.length > 0) {
          // <img> thumbnail element, retrieving thumbnail url
          item.msrc = linkEl.children[0].getAttribute('src');
        }

        item.el = figureEl; // save link to element for getThumbBoundsFn
        items.push(item);
      }

      return items;
    };

    // find nearest parent element
    var closest = function closest(el, fn) {
      return el && ( fn(el) ? el : closest(el.parentNode, fn) );
    };

    // triggers when user clicks on thumbnail
    var onThumbnailsClick = function(e) {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : e.returnValue = false;

      var eTarget = e.target || e.srcElement;

      // find root element of slide
      var clickedListItem = closest(eTarget, function(el) {
        return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
      });

      if(!clickedListItem) {
        return;
      }

      // find index of clicked item by looping through all child nodes
      // alternatively, you may define index via data- attribute
      var clickedGallery = clickedListItem.parentNode,
      childNodes = clickedListItem.parentNode.childNodes,
      numChildNodes = childNodes.length,
      nodeIndex = 0,
      index;

      for (var i = 0; i < numChildNodes; i++) {
        if(childNodes[i].nodeType !== 1) {
          continue;
        }

        if(childNodes[i] === clickedListItem) {
          index = nodeIndex;
          break;
        }
        nodeIndex++;
      }



      if(index >= 0) {
        // open PhotoSwipe if valid index found
        openPhotoSwipe( index, clickedGallery );
      }
      return false;
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    var photoswipeParseHash = function() {
      var hash = window.location.hash.substring(1),
      params = {};

      if(hash.length < 5) {
        return params;
      }

      var vars = hash.split('&');
      for (var i = 0; i < vars.length; i++) {
        if(!vars[i]) {
          continue;
        }
        var pair = vars[i].split('=');
        if(pair.length < 2) {
          continue;
        }
        params[pair[0]] = pair[1];
      }

      if(params.gid) {
        params.gid = parseInt(params.gid, 10);
      }

      return params;
    };

    var openPhotoSwipe = function(index, galleryElement, disableAnimation, fromURL) {
      var pswpElement = document.querySelectorAll('.pswp')[0],
      gallery,
      options,
      items;

      items = parseThumbnailElements(galleryElement);

      // define options (if needed)
      options = {

        // define gallery index (for URL)
        shareButtons: false,
        captionEl: false,
        fullscreenEl: false,
        shareEl: false,
        barsSize: {top:44, bottom:44},
        galleryUID: galleryElement.getAttribute('data-pswp-uid'),
        getThumbBoundsFn: function(index) {
          // See Options -> getThumbBoundsFn section of documentation for more info
          var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
          pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
          rect = thumbnail.getBoundingClientRect();

          return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};
        }

      };

      // PhotoSwipe opened from URL
      if(fromURL) {
        if(options.galleryPIDs) {
          // parse real index when custom PIDs are used
          // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
          for(var j = 0; j < items.length; j++) {
            if(items[j].pid == index) {
              options.index = j;
              break;
            }
          }
        } else {
          // in URL indexes start from 1
          options.index = parseInt(index, 10) - 1;
        }
      } else {
        options.index = parseInt(index, 10);
      }

      // exit if index not found
      if( isNaN(options.index) ) {
        return;
      }

      if(disableAnimation) {
        options.showAnimationDuration = 0;
      }

      // Pass data to PhotoSwipe and initialize it
      gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
      gallery.init();
    };

    // loop through all gallery elements and bind events
    var galleryElements = document.querySelectorAll( gallerySelector );

    for(var i = 0, l = galleryElements.length; i < l; i++) {
      galleryElements[i].setAttribute('data-pswp-uid', i+1);
      galleryElements[i].onclick = onThumbnailsClick;
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    var hashData = photoswipeParseHash();
    if(hashData.pid && hashData.gid) {
      openPhotoSwipe( hashData.pid ,  galleryElements[ hashData.gid - 1 ], true, true );
    }
  };

  // execute above function
  initPhotoSwipeFromDOM('.js-photoswipe');



  /**
  * Magnific Popup
  * Loaded in Drupal Module
  * @author germain@choosit.com
  */
  // ### Image Lightbox
  if($('.image-link').length) {
    $('.image-link').magnificPopup({type:'image'});
  }
  // ### Dailymotion Lightbox
  if($('.video-link-dailymotion').length) {
    $('.video-link-dailymotion').magnificPopup({
      type: 'iframe',
      iframe: {
        patterns: {
          dailymotion: {
            index: 'dailymotion.com',
            id: function(url) {
              var m = url.match(/^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/);
              if (m !== null) {
                if(m[4] !== undefined) {
                  return m[4];
                }
                return m[2];
              }
              return null;
            },
            src: 'http://www.dailymotion.com/embed/video/%id%'
          }
        }
      }
    });
  }
  // ### Video Lightbox
  if($('.video-link-youtube, .video-link-vimeo').length) {
    $('.video-link-youtube, .video-link-vimeo').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-items',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });
  }

});
